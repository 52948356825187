/* eslint-disable camelcase */
import React from "react";
import { Button, TextInput } from "@narmi/design_system";
import UserRoleRadio from "./UserRoleRadio";

const AddUserScreen = ({
  backFunction = () => {},
  create_user_eligible_roles,
  csrf_token,
}) => {
  const submitForm = (evt) => {
    evt.preventDefault();
    document.getElementById("add_user_submit").submit();
  };

  return (
    <div className="column sixteen wide">
      <Button kind="plain" label="Back" onClick={backFunction} />
      <h3 className="fontFamily--body padding--top--xs padding--bottom--s">
        Add New Analytics User
      </h3>
      <text>
        Editors have editing rights, while Viewers only have viewing rights. To
        create an Analytics Admin with editing rights <i>and</i> the ability to
        add new Analytics users, please contact Narmi support.
      </text>
      <form id="add_user_submit" method="POST" className="padding--top--xs">
        <TextInput
          label="Email"
          type="text"
          name="email"
          id="email"
          defaultValue=""
        />
        <UserRoleRadio
          create_user_eligible_roles={create_user_eligible_roles}
        />
        <div className="margin--top">
          <Button label="Add User" onClick={submitForm} />
        </div>
        <input type="hidden" name="csrfmiddlewaretoken" value={csrf_token} />
        <input
          type="hidden"
          name="sisense_user_management_action_type"
          value="create"
        />
      </form>
    </div>
  );
};

export default AddUserScreen;
