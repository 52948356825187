import React, { useState } from "react";
import { Button, Checkbox, Drawer, Row, TextInput } from "@narmi/design_system";
import { DisplayMarkdown } from "./BaoProductSelection";
import ProductForm, { OpwFormError } from "./OpwFormValidator";

const OpwDisclosureLink = ({
  link,
  index,
  setLinksOnDisclosure,
  deleteLink,
  errors,
}) => {
  const [linkText, setLinkText] = useState(link.text);
  const [linkUrl, setLinkUrl] = useState(link.url);
  return (
    <>
      <div style={{ paddingBottom: "var(--space-s)" }}>
        <Row alignItems="center">
          <Row.Item>
            <div style={{ width: "70%" }}>
              <div className="padding--bottom--s">
                <Button
                  kind="plain"
                  onClick={() => {
                    deleteLink(index);
                  }}
                >
                  {`Delete link #${index + 1}`}
                </Button>
              </div>
              <TextInput
                label={`Link text #${index + 1}`}
                value={linkText}
                onChange={(event) => {
                  setLinkText(event.target.value);
                }}
                onBlur={(event) => {
                  const l = link;
                  l.text = event.target.value;
                  setLinksOnDisclosure(l, index);
                }}
                error={errors?.text}
              />
            </div>
          </Row.Item>
          <Row.Item>
            {(linkText || linkUrl) && (
              <div className="padding--bottom--s">
                <Row>
                  <Row.Item>
                    <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                      {linkText}
                    </a>
                  </Row.Item>
                </Row>
              </div>
            )}
          </Row.Item>
        </Row>
      </div>
      <div style={{ paddingBottom: "var(--space-s)" }}>
        <Row alignItems="center">
          <Row.Item>
            <div style={{ width: "70%" }}>
              <TextInput
                label="url"
                value={linkUrl}
                onChange={(event) => {
                  setLinkUrl(event.target.value);
                }}
                onBlur={(event) => {
                  const l = link;
                  l.url = event.target.value;
                  setLinksOnDisclosure(l, index);
                }}
                error={errors?.url}
              />
            </div>
          </Row.Item>
          <Row.Item>
            <div className="padding--bottom--s" />
          </Row.Item>
        </Row>
      </div>
    </>
  );
};

const OpwDisclosure = ({
  disclosure,
  index,
  handleDisclosureDelete,
  setDisclosuresChanges,
  errors = {},
}) => {
  const [markdownField, setMarkdownField] = useState(disclosure.markdown || "");
  const [checkboxTextField, setCheckboxTextField] = useState(
    disclosure.checkbox_text || ""
  );
  const [accepted, setAccepted] = useState(disclosure.accepted || false);
  const [linksError, setLinksError] = useState("");
  const deleteLink = (idx) => {
    const d = disclosure;
    d.links.splice(idx, 1);
    setDisclosuresChanges(d, index);
  };
  const setLinksOnDisclosure = (linkToSet, idx) => {
    const d = disclosure;
    d.links[idx] = linkToSet;
    setDisclosuresChanges(d, index);
  };

  return (
    <>
      <hr className="opw-disclosure-separator" />
      <div className="padding--top--s">
        <Row>
          <Row.Item>
            <div
              style={{ position: "relative", paddingRight: "var(--space-s)" }}
            >
              <Row alignItems="center">
                <Row.Item shrink>
                  <h3 className="fontFamily--body padding--bottom--s">
                    Disclosure #{index + 1}
                  </h3>
                </Row.Item>
                <Row.Item shrink>
                  <div className="padding--bottom--s">
                    <Button
                      kind="plain"
                      startIcon="trash"
                      onClick={() => {
                        handleDisclosureDelete(index);
                      }}
                    />
                  </div>
                </Row.Item>
              </Row>
              <Row alignItems="center">
                <Row.Item>
                  <div
                    style={{ width: "70%", paddingBottom: "var(--space-s)" }}
                  >
                    <TextInput
                      label="markdown"
                      value={markdownField}
                      onChange={(event) => {
                        setMarkdownField(event.target.value);
                      }}
                      onBlur={(event) => {
                        const d = disclosure;
                        d.markdown = event.target.value;
                        setDisclosuresChanges(d, index);
                      }}
                      error={errors?.markdown}
                    />
                  </div>
                </Row.Item>
                <Row.Item>
                  <div className="padding--bottom--s">
                    <DisplayMarkdown markdown={markdownField} />
                  </div>
                </Row.Item>
              </Row>
              {disclosure.links?.length > 0
                ? disclosure.links.map((link, idx) => (
                    <OpwDisclosureLink
                      // eslint-disable-next-line react/no-array-index-key
                      key={`link-${idx}`}
                      link={link}
                      index={idx}
                      setLinksOnDisclosure={setLinksOnDisclosure}
                      deleteLink={(i) => {
                        setLinksError("");
                        deleteLink(i);
                      }}
                      errors={errors ? errors.links?.[idx] : {}}
                    />
                  ))
                : ""}
              <Row alignItems="center">
                <Row.Item>
                  <div
                    style={{ width: "70%", paddingBottom: "var(--space-s)" }}
                  >
                    <TextInput
                      label="Checkbox text"
                      value={checkboxTextField}
                      onChange={(event) => {
                        setCheckboxTextField(event.target.value);
                      }}
                      onBlur={(event) => {
                        const d = disclosure;
                        d.checkbox_text = event.target.value;
                        setDisclosuresChanges(d, index);
                      }}
                      error={errors?.checkbox_text}
                    />
                  </div>
                </Row.Item>
                <Row.Item>
                  <Checkbox
                    field="accepted"
                    label={
                      checkboxTextField || `I agree to the Terms + Conditions`
                    }
                    checked={accepted === true}
                    onChange={() => {
                      setAccepted(!accepted);
                    }}
                  />
                </Row.Item>
              </Row>
            </div>
          </Row.Item>
        </Row>
        <Row>
          <div className="padding--bottom--s">
            <Button
              kind="plain"
              endIcon="plus"
              onClick={() => {
                if (!disclosure.links || disclosure.links.length < 5) {
                  const d = disclosure;
                  d.links.push({ text: "", url: "" });
                  setDisclosuresChanges(d, index);
                  setLinksError("");
                } else {
                  setLinksError(
                    "You may only have five links per disclosure. If you require more, reach out to Customer Support."
                  );
                }
              }}
            >
              Add a new link
            </Button>
          </div>
        </Row>
        <OpwFormError error={linksError} />
      </div>
    </>
  );
};

const OpwDisclosures = ({ formData, setDisclosuresOnForm, errors = {} }) => {
  const disclosures = formData.product?.disclosures || [];
  const setDisclosuresChanges = (d, index = null) => {
    const data = new ProductForm({ ...formData });
    data.product.disclosures[index] = d;
    setDisclosuresOnForm(data);
  };
  const handleDisclosureDelete = (index) => {
    const d = disclosures;
    d.splice(index, 1);
    const copyForm = new ProductForm({ ...formData });
    copyForm.disclosures = d;
    setDisclosuresOnForm(copyForm);
  };
  return (
    <>
      {disclosures.map((disclosure, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="padding--bottom" key={`disclosure+${idx}`}>
          <OpwDisclosure
            disclosure={disclosure}
            index={idx}
            handleDisclosureDelete={handleDisclosureDelete}
            setDisclosuresChanges={setDisclosuresChanges}
            errors={errors[idx]}
          />
        </div>
      ))}
    </>
  );
};

const OpwDisclosuresDrawer = ({ formData, setFormData }) => {
  const [discError, setDiscError] = useState("");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const handleDisclosureAdd = () => {
    const data = new ProductForm({ ...formData });
    data.product.disclosures.push({
      markdown: "",
      checkbox_text: "",
      links: [],
    });
    setFormData(data, "disclosures");
  };

  const setDisclosuresOnForm = (data) => setFormData(data, "disclosures");

  return (
    <>
      <Button
        kind="plain"
        onClick={() => {
          setIsOpenDrawer(true);
        }}
      >
        Edit disclosures
      </Button>
      <Drawer
        isOpen={isOpenDrawer}
        onUserDismiss={() => {
          setIsOpenDrawer(false);
        }}
        showControls={false}
      >
        <Button
          kind="plain"
          endIcon="plus"
          onClick={() => {
            if (formData.product.disclosures.length < 5) {
              handleDisclosureAdd();
              setDiscError("");
            } else {
              setDiscError(
                "You may only have five disclosures per product. If you require more, reach out to Customer Support."
              );
            }
          }}
        >
          Add new disclosure
        </Button>
        <div className="padding--bottom">
          <OpwFormError error={discError} />
        </div>
        <OpwDisclosures
          formData={formData}
          setDisclosuresOnForm={setDisclosuresOnForm}
          errors={
            formData.errors?.disclosures ? formData.errors.disclosures : {}
          }
        />
      </Drawer>
    </>
  );
};

export default OpwDisclosuresDrawer;
