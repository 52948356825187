var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cc from "classcat";
import { Row } from "@narmi/design_system";
var CardTitle = function (_a) {
    var title = _a.title;
    return (_jsx("div", { className: "fontWeight--semibold fontColor--theme--primary margin--bottom--l", children: title }));
};
export var InfoRow = function (_a) {
    var name = _a.name, value = _a.value, _b = _a.expandKey, expandKey = _b === void 0 ? false : _b;
    return (_jsxs("div", { className: cc([
            "info-row".concat(expandKey ? "-expanded-key" : ""),
            "fontSize--s",
            { "margin--top--s": name && value },
            { "margin--top--xs": !(name && value) },
        ]), children: [name && (_jsx("div", { className: "fontColor--heading fontWeight--semibold", children: name })), _jsx("div", { className: "info-row-value", children: value })] }));
};
var renderContent = function (data, useWhiteBackgroundCards, expandKey, action) {
    if (expandKey === void 0) { expandKey = false; }
    if (Array.isArray(data)) {
        return data.map(function (d) { return renderContent(d, useWhiteBackgroundCards, expandKey, action); });
    }
    if (useWhiteBackgroundCards) {
        return (_jsx(_Fragment, { children: Object.entries(data).map(function (_a) {
                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                if (Array.isArray(value)) {
                    return Object.entries(value).map(function (_a) {
                        var _b = __read(_a, 2), rowIndex = _b[0], rowValue = _b[1];
                        return (_jsx(InfoRow, { name: rowIndex.toString() === "0" ? key : "", value: rowValue }, "".concat(key).concat(rowIndex)));
                    });
                }
                if (action)
                    return value;
                return (_jsx(InfoRow, { name: key, value: value, expandKey: expandKey }, key));
            }) }));
    }
    return (_jsx("div", { className: "shaded-container margin--top--s", children: Object.entries(data).map(function (_a, idx) {
            var _b = __read(_a, 2), key = _b[0], value = _b[1];
            if (idx === 0 && key === "Card Title")
                return _jsx(CardTitle, { title: value });
            if (Array.isArray(value)) {
                return Object.entries(value).map(function (_a) {
                    var _b = __read(_a, 2), rowIndex = _b[0], rowValue = _b[1];
                    return (_jsx(InfoRow, { name: rowIndex.toString() === "0" ? key : "", value: rowValue }, "".concat(key).concat(rowIndex)));
                });
            }
            return _jsx(InfoRow, { name: key, value: value }, key);
        }) }));
};
// create a flattened data object/array from getter function and application
var flattenData = function (data) {
    if (Array.isArray(data)) {
        return data.map(flattenData);
    }
    if (!data)
        return {};
    return Object.fromEntries(Object.entries(data)
        .filter(function (_a) {
        var _b = __read(_a, 2), info = _b[1];
        return ("render" in info || ![null, undefined, ""].includes(info.getValue()));
    })
        .map(function (_a) {
        var _b = __read(_a, 2), name = _b[0], info = _b[1];
        if ("render" in info) {
            return [name, info.render()];
        }
        var formatValue = info.formatValue;
        var value = info.getValue();
        return [name, formatValue ? formatValue(value) : value];
    }));
};
var InfoSection = function (_a) {
    var title = _a.title, action = _a.action, data = _a.data, _b = _a.useWhiteBackgroundCards, useWhiteBackgroundCards = _b === void 0 ? false : _b, _c = _a.expandKey, expandKey = _c === void 0 ? false : _c;
    if (!data || !Object.keys(data).length)
        return null;
    return (_jsx(InfoSection.Compositional, { title: title, action: action, useWhiteBackgroundCards: useWhiteBackgroundCards, children: renderContent(data, useWhiteBackgroundCards, expandKey, action) }));
};
InfoSection.Compositional = function (_a) {
    var title = _a.title, action = _a.action, _b = _a.useWhiteBackgroundCards, useWhiteBackgroundCards = _b === void 0 ? false : _b, children = _a.children;
    if (useWhiteBackgroundCards) {
        return (_jsxs("div", { className: "shaded-container-white-bg margin--y--xl", children: [(title || action) && (_jsxs(Row, { children: [_jsx(Row.Item, { children: _jsx("h4", { className: "fontFamily--body margin--bottom--l", children: title }) }), _jsx(Row.Item, { shrink: true, children: action })] })), _jsx("div", { className: "info-section-white-bg", children: children })] }));
    }
    return (_jsxs("div", { className: "info-section", children: [(title || action) && (_jsxs("div", { className: "header-row margin--top--xl ", children: [_jsx("h4", { className: "fontFamily--body", children: title }), action] })), children] }));
};
InfoSection.flattenData = flattenData;
export default InfoSection;
