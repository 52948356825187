import React, { useState } from "react";
import { Button, Dropdown, Dialog, Row } from "@narmi/design_system";
import axios from "axios";
import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved

const ApplicationChangeStateContainer = () => {
  const csrfToken = jsonFromDocument("csrf_token");
  const applicantName = jsonFromDocument("applicant_name");
  const stateMaps = jsonFromDocument("state_maps");
  const state = jsonFromDocument("state");
  const cancelableStates = jsonFromDocument("cancelable_states");
  const currentUserIsNarmi = jsonFromDocument("current_user").is_narmi;
  const backUrl = window.location.href.split("application_change_state")[0];
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [action, setAction] = useState(null);
  const [load, setLoad] = useState(true);

  const handleSubmit = async () => {
    setError(null);
    setLoad(true);
    try {
      await axios.post(
        window.location.pathname,
        { action },
        {
          headers: {
            "X-CSRFToken": csrfToken,
          },
        }
      );
      window.location.href = backUrl;
      setLoad(false);
    } catch (err) {
      setError(
        <div className="ui error message">
          <div className="ui center aligned container">
            <span className="title">{err.response.data.errors.join(", ")}</span>
          </div>
        </div>
      );
      setLoad(false);
    }
  };

  const Header = () => (
    <div
      className="padding--bottom--m"
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <a className="margin--top--s" href={backUrl} aria-label="back">
        <span
          style={{ cursor: "pointer", color: "RGB(var(--nds-black))" }}
          className="narmi-icon-chevron-left fontSize--l"
        />
      </a>
      <h1>Move Application for {applicantName}</h1>
    </div>
  );

  const SectionHeader = (props) => {
    const { title } = props;
    return (
      <div>
        <h3 className="nds-sans padding--top--m fontSize--l fontColor--heading">{title}</h3>
        <hr
          className="margin--bottom--m"
          style={{
            height: 1,
            color: "RGB(var(--nds-medium-grey))",
            backgroundColor: "RGB(var(--nds-medium-grey))",
          }}
        />
        <h2 style={{ marginTop: "3px", marginBottom: "10px" }}>Current Status: {state}</h2>
      </div>
    );
  };

  const ActionSelector = (props) => {
    const { onChange } = props;
    return (
      <Dropdown onChange={onChange} triggerLabel="Action" defaultValue={action}>
        {stateMaps[state]
          // As of right now, we don't want to allow users the option to retry opening on core
          // Or cancel applications from states other than AccountApplication.CREATING
          // Only making this option available to narmi employees
          .filter((actionOption) => {
            if (actionOption === "canceled" && !cancelableStates.includes(state)) return false;
            return currentUserIsNarmi || actionOption !== "retry opening on core";
          })
          .map((actionOption) => (
            <div key={actionOption}>{actionOption}</div>
          ))}
      </Dropdown>
    );
  };

  return (
    <div>
      <Header />
      {error}
      <SectionHeader title="Changing application status should only be attempted if previous errors have been corrected" />
      <ActionSelector
        value={state}
        onChange={(event) => {
          if (event === "opened") {
            setIsDialogOpen(true);
          }
          setAction(event);
          setLoad(false);
        }}
      />
      <div className="update-application-actions" style={{ float: "right", marginTop: "2rem" }}>
        <Button
          kind="negative"
          disabled={load}
          label="Cancel"
          onClick={() => {
            window.location.href = backUrl;
          }}
        />
        <Button
          kind="primary"
          disabled={load}
          label="Update Application State"
          onClick={handleSubmit}
          style={{ marginLeft: "var(--space-l)" }}
        />
      </div>
      <Dialog
        title="Confirm that application is opened on core"
        isOpen={isDialogOpen}
        onUserDismiss={() => {
          window.location.href = backUrl;
        }}
        footer={
          <Row alignItems="center" justifyContent="end">
            <Row.Item shrink>
              <Button
                kind="negative"
                label="Cancel"
                onClick={() => {
                  window.location.href = backUrl;
                }}
              />
            </Row.Item>
            <Row.Item shrink>
              <Button
                kind="primary"
                label="Confirm"
                onClick={() => {
                  setIsDialogOpen(false);
                }}
              />
            </Row.Item>
          </Row>
        }
      >
        <div className="padding--y">
          <div>
            {`Please only move this application to an "Opened" status if all records have been created on the core and funding posted. If not, reach out to Support to understand why the application did not open automatically and to retry opening once the issue is resolved.`}
            <br />
            <br />
            {`Using this action will not retry opening. It will only mark the application as "Opened", which will trigger an email to the applicant. If you want to proceed, click "Confirm" and then click "Update Application State". Otherwise, click "Cancel".`}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ApplicationChangeStateContainer;
