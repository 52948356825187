import PropTypes from "prop-types";

export const ALLOWED_CATEGORIES = [
  "checking",
  "savings",
  "hsa",
  "money_market",
  "certificate_of_deposit",
];
export const OPTIONAL_FIELDS = [
  "features",
  "hidden",
  "max_count",
  "options",
  "metadata",
  "required",
  "required_for_existing_members",
  "can_add_beneficiaries",
  "recommended",
  "maximum_balance",
  "monthly_service_fee",
  "more_details_url",
  "more_details_copy",
  "debit_card_required",
  "account_activity_questions",
];
const SKIP_FIELDS = ["details", "disclosures"];
export const REQUIRED_FIELDS = [
  "id",
  "name",
  "description",
  "category",
  "minimum_balance",
  "display_order",
  "public",
  "exclusive_application_types",
];
export const ALL_FIELDS = [
  ...REQUIRED_FIELDS,
  ...OPTIONAL_FIELDS,
  ...SKIP_FIELDS,
];
export const PERSONAL_APP_TYPES = { personal: "personal" };
export const BUSINESS_APP_TYPES = {
  sole_proprietor: "sole_proprietor",
  general_partnership: "general_partnership",
  llc: "limited_liability_company",
  corporation: "corporation",
};
export const ALL_APP_TYPES = { ...PERSONAL_APP_TYPES, ...BUSINESS_APP_TYPES };

class Product {
  constructor(props) {
    this.id = props.id || "";
    this.name = props.name || "";
    this.debit_card_required = props.debit_card_required || "";
    this.description = props.description || "";
    this.category = props.category || "checking";
    this.minimum_balance = props.minimum_balance || "0";
    this.maximum_balance = props.maximum_balance;
    this.details = props.details || ["", "", ""];
    this.features = props.features || {};
    this.max_count = props.max_count;
    this.options = props.options || {};
    this.metadata = props.metadata || {};
    this.public = props.public || false;
    this.exclusive_application_types = props.exclusive_application_types || [];
    this.required = props.required;
    this.required_for_existing_members = props.required_for_existing_members;
    this.can_add_beneficiaries = props.can_add_beneficiaries;
    this.recommended = props.recommended;
    this.hidden = props.hidden;
    this.disclosures = props.disclosures || [];
    this.display_order = props.display_order;
    this.monthly_service_fee = props.monthly_service_fee;
    this.more_details_url = props.more_details_url;
    this.more_details_copy = props.more_details_copy;
    this.account_activity_questions = props.account_activity_questions;
  }

  toObject() {
    const ret = {};
    ALL_FIELDS.forEach((f) => {
      ret[f] = this[f];
    });
    return ret;
  }

  toJson() {
    return JSON.stringify(this.toObject());
  }
}
Product.propTypes = {
  // from indigo/settings/schemas.py
  // sha: f550be4127861e9ddf112b25abb69fbea9daa0fd
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  category: PropTypes.oneOf(ALLOWED_CATEGORIES),
  minimum_balance: PropTypes.number.isRequired,
  maximum_balance: PropTypes.number.isRequired,
  details: PropTypes.arrayOf(PropTypes.string).isRequired,
  features: PropTypes.shape({
    overdraft: PropTypes.string,
  }),
  max_count: PropTypes.number,
  options: PropTypes.shape({
    debit_card: PropTypes.oneOf([
      "boolean",
      PropTypes.shape({
        card_type: PropTypes.oneOf(["20", null, undefined]),
        "create:CardCreateableFields": PropTypes.shape({
          IssueCode: PropTypes.oneOf(["100", null, undefined]),
        }),
      }),
    ]),
    courtesy_pay: PropTypes.oneOf(["boolean"]),
  }),
  metadata: PropTypes.shape({
    minimum_suffix: PropTypes.string.isRequired,
    maximum_suffix: PropTypes.string.isRequired,
  }),
  public: PropTypes.bool.isRequired,
  exclusive_application_types: PropTypes.oneOf(Object.values(ALL_APP_TYPES)),
  required: PropTypes.bool,
  required_for_existing_members: PropTypes.bool,
  can_add_beneficiaries: PropTypes.bool,
  recommended: PropTypes.bool,
  disclosures: PropTypes.shape({
    checkbox_text: PropTypes.string,
    markdown: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      })
    ),
  }),
  debit_card_required: PropTypes.bool,
  monthly_service_fee: PropTypes.string,
  more_details_url: PropTypes.string,
  more_details_copy: PropTypes.string,
};
Product.defaultProps = {
  id: "",
  name: "",
  description: "",
  category: "checking",
  minimum_balance: 0,
  details: ["", "", ""],
  public: false,
};
export default Product;
