import React, { useState, useEffect } from "react";
import {
  LoadingSkeleton,
  Row,
  Button,
  ContentCard,
  Tag,
} from "@narmi/design_system";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import CopyableCell from "../components/CopyableCell";
import ConditionalMessage from "../components/ConditionalMessage";

export default function FunctionsList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [functions, setFunctions] = useState([]);
  const termsLastUpdated = moment(jsonFromDocument("terms_last_updated"));
  const coreVpnExists = jsonFromDocument("core_vpn_exists", false);

  useEffect(() => {
    async function fetchFunctions() {
      const functionsRes = await fetch("/v1/functions");

      if (!functionsRes.ok) {
        setError(functionsRes.json() || functionsRes.statusText);
        return;
      }
      const functionsJson = await functionsRes.json();
      setFunctions(functionsJson.results);
      setIsLoading(false);
    }
    fetchFunctions();
  }, []);

  function termsUpdatedMessage() {
    const now = moment();
    if (
      termsLastUpdated > now ||
      Math.abs(now.diff(termsLastUpdated, "days")) < 30
    ) {
      return (
        <div className="ui info message margin--y--l">
          <div className="ui center aligned container">
            <span className="title">
              The{" "}
              <a
                href="https://www.narmi.com/policies/functions-terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Service for Narmi Functions{" "}
              </a>
              have been updated with an effective date of{" "}
              {termsLastUpdated.format("MMMM Do YYYY")}. Please review the
              updated Terms of Service carefully.
            </span>
          </div>
        </div>
      );
    }
    return null;
  }

  const getLambdaInvokeUrl = (func) => {
    if (func.type !== "naf") {
      return (
        func.lambda_invoke_url && (
          <Row.Item shrink>
            <CopyableCell
              title="Function URL"
              text={func.lambda_invoke_url}
              truncateLength={30}
            />
          </Row.Item>
        )
      );
    }
    return (
      func.type_metadata?.frontend_url && (
        <Row.Item shrink>
          <CopyableCell
            title="Function URL"
            text={func.type_metadata?.frontend_url}
            truncateLength={30}
          />
        </Row.Item>
      )
    );
  };

  function functionCard(func) {
    const titleCaseState =
      func.state.charAt(0).toUpperCase() + func.state.substr(1).toLowerCase();
    return (
      <div className="padding--bottom" key={func.uuid}>
        <ContentCard
          testId="nf-contentcard"
          kind="interactive"
          onClick={() => history.push(`/functions/${func.uuid}`)}
        >
          <Row gapSize="s">
            <Row.Item>
              <h3 className="fontSize--heading4 padding--bottom--s">
                {func.name}
              </h3>
            </Row.Item>
            <Row.Item shrink>
              {coreVpnExists && func?.type_metadata?.core_vpn_enabled ? (
                <Tag testId="core-access-tag" label="Core VPN" />
              ) : null}
            </Row.Item>
            <Row.Item shrink>
              <Tag label={titleCaseState} />
            </Row.Item>
          </Row>
          <Row>
            <Row.Item />
            <Row.Item shrink>{getLambdaInvokeUrl(func)}</Row.Item>
          </Row>
        </ContentCard>
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Row.Item>
          <h1>Narmi Functions</h1>
        </Row.Item>
        <Row.Item shrink>
          <Link to="/functions/new">
            <Button
              kind="primary"
              label="Create a new function"
              disabled={isLoading}
            />
          </Link>
        </Row.Item>
      </Row>
      <ConditionalMessage message={error} />
      {termsUpdatedMessage()}
      <LoadingSkeleton isLoading={isLoading}>
        <div className="padding--y">
          {functions?.length > 0 ? (
            functions.map((fn) => functionCard(fn))
          ) : (
            <div className="ui info message margin--y--xl">
              <div className="ui center aligned container">
                <span className="title">No Narmi Functions found.</span>
              </div>
            </div>
          )}
        </div>
      </LoadingSkeleton>
    </div>
  );
}
