/* eslint-disable camelcase */
import React from "react";
import { Button } from "@narmi/design_system";

import UserRoleRadio from "./UserRoleRadio";

const UpdateUserScreen = ({
  backFunction = () => {},
  create_user_eligible_roles,
  user_to_update_email,
  user_to_update_id,
  csrf_token,
}) => {
  const submitForm = (evt) => {
    evt.preventDefault();
    document.getElementById("update_user_submit").submit();
  };

  return (
    <div className="column sixteen wide">
      <Button kind="plain" label="Back" onClick={backFunction} />
      <h3 style={{ marginTop: "0.5rem" }}>Update existing user</h3>
      <form id="update_user_submit" method="POST">
        <p>What role do you want {user_to_update_email} to have?</p>
        <UserRoleRadio
          create_user_eligible_roles={create_user_eligible_roles}
        />
        <div className="margin--top">
          <Button label="Update User" onClick={submitForm} />
        </div>
        <input type="hidden" name="user_id" value={user_to_update_id} />
        <input type="hidden" name="csrfmiddlewaretoken" value={csrf_token} />
        <input
          type="hidden"
          name="sisense_user_management_action_type"
          value="update_role"
        />
      </form>
    </div>
  );
};

export default UpdateUserScreen;
